import { useLocation, useNavigate } from "react-router-dom";
import styles from "./drawer.module.css";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { ChatsContext } from "../../Components/contexts/chatsContext/msgsContext";

let msgsNumber = 0;

let msgsNumberStyles = {
  color: "white",
  fontSize: ".5rem",
  background: "red",
  borderRadius: "50%",
  width: "15px",
  height: "15px",
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
  margin: "0 8px",
};
const msgsNumberStylesWithClosedDrawer = {
  position: "absolute",
  top: "45%",
  right: "23%",
  margin: "0",
};
const Drawerlink = ({
  getHoveredDivCoord,
  text,
  Icon,
  path,
  subLinks,
  open = false,
  index,
  linksLength,
  isMultiLinksOverFlowHidden,
  setSubLinksArr,
}) => {
  // ----------------------open close drawer-------------------------------------------
  const { customersCollections } = useContext(ChatsContext);
  // ----------------------open close drawer-------------------------------------------
  useEffect(() => {
    msgsNumber = 0;
    for (let key in customersCollections) {
      customersCollections[key]?.forEach((customer) => {
        if (!customer?.data()?.is_read && customer?.data()?.sender !== "0000") {
          msgsNumber++;
        }
      });
    }
  }, [customersCollections]);
  const { i18n } = useTranslation();
  const Navigate = useNavigate();
  const location = useLocation();
  let Currenturl = new URL(location.pathname, process.env.REACT_APP_BASE_URL);
  let linkurl = new URL(path, process.env.REACT_APP_BASE_URL);
  const rowRef = useRef();
  const [renderedChildrenIndex, setRenderedChildrenIndex] = useState(null);

  // --------------------------------------------------------------------------------------------
  useEffect(() => {
    // ----------------------------------------------------------------------------------
    return () => {
      setRenderedChildrenIndex(null);
    };
  }, []);

  return (
    <div
      style={{ zIndex: "1200" }}
      id={renderedChildrenIndex === index ? "hoveredDiv" : ""}
    >
      {path ? (
        <div
          ref={rowRef}
          onClick={() => {
            Navigate(path);
          }}
          className={`${
            Currenturl?.pathname === linkurl.pathname && styles.selected_row
          }  ${styles.nav_row} link_without_sublinks`}
          style={{ position: "relative", overflow: "hidden" }}
        >
          {typeof Icon === "string" ? (
            <img
              className={` ${styles.nav_icon}`}
              style={{ height: "1.2rem" }}
              src={Icon}
              alt=""
            />
          ) : (
            <Icon className={` ${styles.nav_icon}`} />
          )}
          <div className={styles.nav_text}>
            {text}
            {text === "Chat" && msgsNumber > 0 && (
              <p
                style={
                  open
                    ? msgsNumberStyles
                    : {
                        ...msgsNumberStyles,
                        ...msgsNumberStylesWithClosedDrawer,
                      }
                }
              >
                {msgsNumber}
              </p>
            )}
          </div>
        </div>
      ) : !subLinks?.length ? (
        <></>
      ) : (
        <div
          onMouseOver={(e) => {
            getHoveredDivCoord(e);
            setSubLinksArr(subLinks);
            setRenderedChildrenIndex(index);
          }}
          onMouseLeave={() => {
            setRenderedChildrenIndex(null);
          }}
          className={` ${
            index === renderedChildrenIndex && styles.selected_row
          }  ${styles.nav_row} ${
            subLinks
              ?.map((el) => {
                return new URL(el.path, process.env.REACT_APP_BASE_URL);
              })
              ?.filter((el) => el.pathname === Currenturl.pathname)?.length >
              0 && styles.selected_row
          }`}
          style={{
            position: "relative",
            overflow: isMultiLinksOverFlowHidden ? "hidden" : "visible",
          }}
        >
          {typeof Icon === "string" ? (
            <img
              className={` ${styles.nav_icon}`}
              style={{ height: "1.2rem" }}
              src={Icon}
              alt=""
            />
          ) : (
            <Icon className={` ${styles.nav_icon}`} />
          )}
          {
            <span
              className={`${
                open ? styles.opened_nav_text : styles.closed_nav_text
              }`}
            >
              {text}
            </span>
          }
          {open && i18n.language === "en" ? (
            <KeyboardArrowRightIcon
              sx={{ right: "10px" }}
              className={styles.right_arrow}
            />
          ) : (
            open && (
              <KeyboardArrowLeftIcon
                sx={{
                  right: "10px",
                  position: "absolute",
                }}
              />
            )
          )}
        </div>
      )}
    </div>
  );
};

export { Drawerlink };
