import { IconButton, Slide, Snackbar } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const Errmodal = ({ errorMsg, seterrorMsg }) => {
    const { t } = useTranslation();

    const [transition, setTransition] = useState(undefined);

    function TransitionRight(props) {
        return <Slide {...props} direction="right" />;
    }
    const handleTranstion = () => {
        setTransition(() => TransitionRight);
    };

    useEffect(() => {
        if (errorMsg) { handleTranstion() }
    }, [errorMsg]);
    const close = (
        <>
            <IconButton
                size="small"
                aria-label="close"

                className=' text-danger'
            >
            </IconButton>
        </>
    );

    return (
        <div >

            <Snackbar
                TransitionComponent={transition}
                open={!!errorMsg}
                autoHideDuration={4000}
                onClose={() => { seterrorMsg('') }}
                message={t(errorMsg)}
                action={close}
            />

        </div>

    );
}

export { Errmodal };
