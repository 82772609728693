import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Navigation from "./AppNavigation/Navigation";
import { Layout } from "./outline/layout/layout";
import { jwtInterceptor } from "./shared/apiCall/ApiCall";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useEffect, useMemo } from "react";
import { ErrMProvider } from "./Components/contexts/errmsgContext/errMsgContext";
import { useTranslation } from "react-i18next";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { ChatsProvider } from "./Components/contexts/chatsContext/msgsContext";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
const queryClient = new QueryClient();

function App() {
  const { i18n } = useTranslation();

  const cacheRtl = createCache({
    key: "css",
    stylisPlugins: [prefixer, rtlPlugin],
  });

  const cacheLtr = createCache({
    key: "css",
  });
  useEffect(() => {
    let dir = i18n.language === "en" ? "ltr" : "rtl";
    document.getElementsByTagName("html")[0].setAttribute("dir", dir);
  }, [i18n.language]);

  const theme = useMemo(
    () =>
      createTheme({
        direction: i18n.language === "en" ? "ltr" : "rtl",
      }),
    [i18n.language]
  );
  jwtInterceptor();

  return (
    <div className="App">
      <div className="wrapper">
        <div
          style={{
            display: "flex",
            height: "100%",
            minHeight: "87vh",
            overflow: "scroll initial",
            flexGrow: "1",
          }}
        >
          <CacheProvider value={i18n.language === "ar" ? cacheRtl : cacheLtr}>
            <ThemeProvider theme={theme}>
              <ChatsProvider>
                <Layout>
                  <QueryClientProvider client={queryClient}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <ErrMProvider>
                        <Navigation />
                      </ErrMProvider>
                    </LocalizationProvider>
                  </QueryClientProvider>
                </Layout>
              </ChatsProvider>
            </ThemeProvider>
          </CacheProvider>
        </div>
      </div>
    </div>
  );
}

export default App;
