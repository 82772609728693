import axios from "axios";
import { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Errmodal } from '../../../shared/errormodal/errormodal'
import { requestToken } from "../../../Firebase/Firebase";
export const AuthContext = createContext()


const AuthContextProvider = ({ children }) => {
    // hooks
    const [errorMsg, seterrorMsg] = useState('');

    const [currentUser, setCurrentUser] = useState(JSON.parse(localStorage.getItem('greenUser')) ||
        JSON.parse(sessionStorage.getItem('greenUser')) || null);
    const navigate = useNavigate()

    // methods
    //    
    const [fcmToken, setFcmToken] = useState();

    const registerFcmtokenToTopics = async (user) => {
        try {
            let res = await axios.patch(`${process.env.REACT_APP_BASE_URL}/staff/staff-details/${user?.id}`,
                { "fcm_token": fcmToken });

        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        localStorage.getItem('greenUserFcmToken')
        // if (fcmToken !== localStorage.getItem('greenUserFcmToken') && fcmToken && currentUser?.permissions?.includes('view_chat_system')) {
        //     localStorage.getItem('greenUserFcmToken');
        //     registerFcmtokenToTopics(currentUser)
        // }
        if (fcmToken && currentUser?.permissions?.includes('view_chat_system')) {
            registerFcmtokenToTopics(currentUser)
            // console.log(fcmToken);
        }
    }, [currentUser, fcmToken]);

    // useEffect(() => {
    //     requestToken(setFcmToken);

    // }, []);
    // --------------------------------------------------------------------------------------------
    const removeUserFromStorage = () => {
        localStorage.removeItem("greenUser")
        sessionStorage.removeItem("greenUser")
        localStorage.removeItem('greenUserFcmToken');
    }
    // --------------------------------------------------------------------------------------------
    const login = async (user, rememberMe) => {

        try {
            let res = await axios.post(`${process.env.REACT_APP_BASE_URL}/Account/dash-login/`, {
                "email": user?.email,
                "password": user?.password
            })
            setCurrentUser(res?.data);
            if (rememberMe) {
                localStorage.setItem("greenUser", JSON.stringify({ ...res?.data }))
            } else {
                sessionStorage.setItem("greenUser", JSON.stringify({ ...res?.data }));
            }
            await requestToken(setFcmToken);
            // await registerFcmtokenToTopics(res?.data);
            // navigate('/');
        } catch (err) {
            throw err
        }
    };
    // --------------------------------------------------------------------------------------------
    // logout
    const logout = async () => {
        try {
            await axios.post(`${process.env.REACT_APP_BASE_URL}/Account/logout/`, {}, { headers: { 'Authorization': `Token ${currentUser?.token}` } })
            removeUserFromStorage()
            setCurrentUser(null);
            navigate('/login')
        }
        catch (err) {
            console.log(err);
        }


    };
    // --------------------------------------------------------------------------------------------
    // authenticate user
    const authenticateUser = async (token) => {
        try {
            const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/Account/auth2/`, {}, { headers: { 'Authorization': `Token ${token}` } })
            if (localStorage.getItem('greenUser')) {
                localStorage.setItem("greenUser", JSON.stringify({ ...res?.data }));
            } else if (sessionStorage.getItem('greenUser')) {
                sessionStorage.setItem("greenUser", JSON.stringify({ ...res?.data }));
            }
            setCurrentUser(res.data);
        }
        catch (err) {
            if (err.response?.status === 401) {
                removeUserFromStorage();
                setCurrentUser(null);
            } else {
                seterrorMsg('Something went wrong, please try again');
            }
        }
    }
    // --------------------------------------------------------------------------------------------
    // effect
    useEffect(() => {
        if (localStorage.getItem('greenUser')) {
            // setCurrentUser(JSON.parse(localStorage.getItem('greenUser')));
            authenticateUser(JSON.parse(localStorage.getItem('greenUser'))?.token);
        } else if (sessionStorage.getItem('greenUser')) {
            authenticateUser(JSON.parse(sessionStorage.getItem('greenUser'))?.token);
        }

    }, []);
    // --------------------------------------------------------------------------------------------
    return (
        <AuthContext.Provider value={{ currentUser, login, logout }}>
            {children}
            {errorMsg && <Errmodal errorMsg={errorMsg} seterrorMsg={seterrorMsg} />}
        </AuthContext.Provider>
    );
}

export { AuthContextProvider };
