import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ErrorBoundary } from "./Components/errorboundary/errorboudary";
import { LoadScript } from "@react-google-maps/api";
import { Loading } from "./Components/loader/loading";
import { HashRouter } from "react-router-dom";
import { AuthContextProvider } from "./Components/contexts/authContext/authContext";
import { SucMsgProvider } from "./Components/contexts/secMsgContext/sucMsgContext";
import { ErrMProvider } from "./Components/contexts/errmsgContext/errMsgContext";
import { ChatsProvider } from "./Components/contexts/chatsContext/msgsContext";
import "./i18n";

{
  /* <script
  // async
  src={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_APIKEy}&loading=async&libraries=drawing&callback=initMap`}
></script>; */
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ErrorBoundary>
    <LoadScript
   loading= {'async'}
      loadingElement={<Loading />}
      libraries={["places"]}
      googleMapsApiKey={process.env.REACT_APP_GOOGLEMAPSAPIKEY}
    >
      <HashRouter>
        <AuthContextProvider>
          {/* <NotificationsContextProvider> */}

          <SucMsgProvider>
            <App />
          </SucMsgProvider>
          {/* </NotificationsContextProvider> */}
        </AuthContextProvider>
      </HashRouter>
    </LoadScript>
  </ErrorBoundary>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
