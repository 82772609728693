import { useEffect, useState } from 'react';
import Alert from '@mui/material/Alert';
import styles from './successmodal.module.css'
import { useTranslation } from 'react-i18next';
import { IconButton, Slide, Snackbar } from '@mui/material';

function hasEnglishLetters(text) {
    var englishLettersRegex = /[a-zA-Z]/;
  
    return englishLettersRegex.test(text);
  }

const Successmodal = ({ successMsg, setSuccessMsg }) => {
    const { i18n } = useTranslation();


    const [transition, setTransition] = useState(undefined);

    function TransitionRight(props) {
        return <Slide {...props} direction="right" />;
    }
    const handleTranstion = () => {
        setTransition(() => TransitionRight);
    };

    useEffect(() => {
        if (successMsg) { handleTranstion() }
    }, [successMsg]);

    // 


    const close = (
        <>
            <IconButton
                size="small"
                aria-label="close"
                className=' text-danger'
            >
            </IconButton>
        </>
    );
    return (
        <div className={styles.suc_msg}>
            <Snackbar
                TransitionComponent={transition}
                open={!!successMsg}
                autoHideDuration={2000}
                onClose={() => { setSuccessMsg('') }}
                message={i18n.language === "en" ? successMsg :i18n.language === "ar"&&!hasEnglishLetters(successMsg)? successMsg : 'تم'}
                action={close}
            />        
            </div>
    );
}

export { Successmodal };
