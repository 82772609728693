
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../../Components/contexts/authContext/authContext';
import styles from './user-details.module.css';
import avtar from '../../../assets/Image/drivers-avatar.png'
import user_icon from '../../../assets/Image/user_icon.png'
import phone_icon from '../../../assets/Image/phone_icon.png'
import name_icon from '../../../assets/Image/name_icon.png'
import email_icon from '../../../assets/Image/email_icon.png'
import id_icon from '../../../assets/Image/id_icon.png'
import role_icon from '../../../assets/Image/role_icon.png'

const style = {
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    boxShadow: 24,
    outline: 'none',
    // p: 3,
    borderRadius: "15px",
    display:'flex',
    justifyContent:"space-between",
    flexWrap:'wrap'
};



export function UserInfoModal({ open, setOpen }) {
    const { t } = useTranslation();
    const { i18n } = useTranslation();

    const { currentUser } = useContext(AuthContext)



    // --------------------------------------------------------------------------------------------

    // --------------------------------------------------------------------------------------------

    return (
        <div>
            <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                onClose={() => {
                    setOpen(false)
                }}
            >
                <Box sx={style}>
                <div className={styles.user_card_wrapper}>
            {/*  */}
            <div className={styles.left_sec_wrapper}>

                <img className={styles.profile_img} alt='' src={currentUser?.image != null ? process.env.REACT_APP_BASE_URL + currentUser?.image : avtar} />

            

            </div>
            {/*  */}
            <div className={styles.right_sec_wrapper}>
            <div className={styles.profile_row_wrapper}>
                    <div><span><img className={i18n.language === "en" ? styles.icon_wrapper_en : styles.icon_wrapper_ar} src={name_icon} alt='' /></span>
                        <span>{t('Full Name')}</span>
                    </div>
                    <div>{currentUser?.full_name}</div>
                </div>

                <div className={styles.profile_row_wrapper}>
                    <div><span><img className={i18n.language === "en" ? styles.icon_wrapper_en : styles.icon_wrapper_ar} src={user_icon} alt='' /></span>
                        <span>{t('Username')}</span>
                    </div>
                    <div>{currentUser?.username}</div>
                </div>

                <div className={styles.profile_row_wrapper}>
                    <div><span><img className={i18n.language === "en" ? styles.icon_wrapper_en : styles.icon_wrapper_ar} src={phone_icon} alt='' /></span>
                        <span>{t('Phone Number')}</span>
                    </div>
                    <div>{currentUser?.phone_number}</div>
                </div>

             

                <div className={styles.profile_row_wrapper}>
                    <div><span><img className={i18n.language === "en" ? styles.icon_wrapper_en : styles.icon_wrapper_ar} src={email_icon} alt='' /></span>
                        <span>{t('Email')}</span>
                    </div>
                    <div>{currentUser?.email}</div>
                </div>
                <div className={styles.profile_row_wrapper}>
                    <div><span className={i18n.language === "en" ? styles.icon_wrapper_en : styles.icon_wrapper_ar}><img alt='' style={{width:'19px'}} src={id_icon} /></span>
                        <span>{t('Account ID')}</span>
                    </div>
                    <div>{currentUser?.account_id}</div>
                </div>
                <div className={styles.profile_row_wrapper}>
                    <div><span className={i18n.language === "en" ? styles.icon_wrapper_en : styles.icon_wrapper_ar}><img alt='' style={{width:'19px'}} src={role_icon} /></span>
                        <span>{t('User Role')}</span>
                    </div>
                    <div>{currentUser?.profile_type === 2 ? 'CUSTOMER SERVICE'
                 : currentUser?.profile_type === 3 ? 'FINANCE' : currentUser?.profile_type === 4 ? 'ADMIN'
                 : currentUser?.profile_type === 5 ? 'MANAGER' : ''}</div>
                </div>
                <div className={styles.profile_row_wrapper}>
                   
                </div>

              
            </div>

        </div>

                </Box>
            </Modal>
        </div>
    );
}