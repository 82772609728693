import React, { useContext, useEffect } from 'react';
import { createContext } from 'react';
import { useState } from 'react';
import { getCustomers } from '../../../Firebase/Firebase';
// import { ErrMsgContext } from '../errmsgContext/errMsgContext';

const collectionsArr = [process.env.REACT_APP_FIREBASE_DRIVERS_CHAT_COLLECTION, process.env.REACT_APP_FIREBASE_PASSENGERS_CHAT_COLLECTION]
export const ChatsContext = createContext();

const ChatsProvider = ({ children }) => {
    // const { updateErrMsg } = useContext(ErrMsgContext);
    const [customersCollections, setCustomersCollections] = useState({})
    const [chats, setChats] = useState({ 'customer-service': undefined, 'customer-service2': undefined, 'customer-service3': undefined, 'customer-service4': undefined, 'customer-service5': undefined, 'customer-service6': undefined });
    // --------------------------------------------------------------------------------------------
    useEffect(() => {
        collectionsArr.forEach((firebaseCollection) => {
            try {
                getCustomers(firebaseCollection, setCustomersCollections, (customers) => {
                    //    setCustomers(customers);
                })
            } catch (err) {
                console.log(err);
                // updateErrMsg('something went wrong with getting users ,please try again')
            }
        })


    }, []);
    // --------------------------------------------------------------------------------------------

    const updateChats = (chats) => {
        setChats(chats)
    }
    const updateCustomersCollections = (customersCollections) => {
        setCustomersCollections(customersCollections)
    }
    return (
        <ChatsContext.Provider value={{ chats, updateChats, children, customersCollections, updateCustomersCollections }}>
            {children}
        </ChatsContext.Provider>
    );

}

export { ChatsProvider };

