
import axios from 'axios';

export function jwtInterceptor() {

    axios.interceptors.request.use((request )=> {
        // add auth header with jwt if account is logged in and request is to the api url
        request.headers.common["Accept-Language"] = `0`;

        let currentUser=JSON.parse(localStorage.getItem('greenUser')) ||
        JSON.parse(sessionStorage.getItem('greenUser')) || null
        if (currentUser ) {
            request.headers.common.Authorization = `Token ${currentUser.token}`;
        }
        return request;
    });
    axios.interceptors.response.use(response => response,
  error => {
    if (error?.response?.status === 401) {
      // window.location.href = '/#/login';
    }
    throw error
  });
}