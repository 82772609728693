import { createContext, useState } from "react";
import { Errmodal } from "../../../shared/errormodal/errormodal";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
export const ErrMsgContext = createContext();

// --------------------------------------------------------------------------------------------
const removeUserFromStorage = () => {
  localStorage.removeItem("greenUser");
  sessionStorage.removeItem("greenUser");
};
// --------------------------------------------------------------------------------------------

const ErrMProvider = ({ children }) => {
  const { t } = useTranslation();

  const Navigate = useNavigate();
  const [errorMsg, seterrorMsg] = useState("");

  const updateErrMsg = (err) => {
    console.log(err);

    if (typeof err === "object") {
      switch (err.request?.status) {
        case 400:
          let errMsg = "";
          if (err?.response?.data instanceof Array) {
            errMsg = err?.response?.data?.join(" ");
          } else {
            for (let key in err?.response?.data) {
              if (typeof err?.response?.data[key] === "string") {
                errMsg = errMsg
                  ? ", "
                  : "" + errMsg + key !== "non_field_errors"
                  ? key.split("_").join(" ").toUpperCase() +
                    " : " +
                    err?.response?.data[key]
                  : "" + err?.response?.data[key];
              }
              if (err?.response?.data[key] instanceof Array) {
                errMsg = errMsg
                  ? errMsg +
                    ", " +
                    (key !== "non_field_errors"
                      ? key.split("_").join(" ").toUpperCase() + " : "
                      : "") +
                    (err?.response?.data[key] instanceof Array
                      ? err?.response?.data[key].join(",")
                      : err?.response?.data[key])
                  : (key !== "non_field_errors"
                      ? key.split("_").join(" ").toUpperCase() + " : "
                      : "") +
                    (err?.response?.data[key] instanceof Array
                      ? err?.response?.data[key].join(",")
                      : err?.response?.data[key]);
              }
            }
          }
          seterrorMsg(errMsg || t("bad request"));
          break;
        case 406:
          seterrorMsg(t("Not Acceptable"));
          break;
        case 404:
          seterrorMsg(t("Not Found"));
          break;
        case 500:
          seterrorMsg(t("Internal Server Error"));
          break;
        case 401:
          removeUserFromStorage();
          console.log("unauthorized");
          Navigate("/login");
          // seterrorMsg('unauthorized')
          break;
        case 302:
          seterrorMsg(t("Item already exists"));
          break;
        default:
          seterrorMsg(t("Something went wrong, please try again"));
      }
    } else {
      seterrorMsg(err);
    }
  };
  return (
    <ErrMsgContext.Provider value={{ updateErrMsg, errorMsg }}>
      {children}
      {errorMsg && <Errmodal errorMsg={errorMsg} seterrorMsg={seterrorMsg} />}
    </ErrMsgContext.Provider>
  );
};

export { ErrMProvider };
