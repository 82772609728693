import React from "react";
import Paths from "./Routes";
import { Route, Routes } from "react-router-dom";
import { Authuser } from "../Components/authuser/authuser";
import { Suspense } from "react";
import { NonAuthUser } from "../Components/nonAuthUser/nonAuthUser";
// import { AdminUser } from '../Components/adminUser/adminUser';
import Notfound from "../Components/notfound/notFound";
import { Loading } from "../Components/loader/loading";

export default function Navigation() {
  const Spinner = <Loading />;
  const Chat = React.lazy(() => import("../Components/chat/ChatPage"));

  const DriverTransactionDetails = React.lazy(() =>
    import(
      "../Components/drivers2/driverTransactionsDetails/driverTransactionDetails"
    )
  );
  const PassengerTransactionDetails = React.lazy(() =>
    import(
      "../Components/passengers/passengerTransactionsDetails/passengerTransactionDetails"
    )
  );
  const Cars = React.lazy(() => import("../Components/carList/carList"));
  const Login = React.lazy(() => import("../Components/login/Login"));
  const SiteMap = React.lazy(() => import("../Components/sitemap/sitemap"));
  const DriverDetails = React.lazy(() =>
    import("../Components/driverdetails/driverdetails")
  );
  const Stations = React.lazy(() => import("../Components/stations/stations"));
  const Home = React.lazy(() => import("../Components/home/home"));
  const Passengers = React.lazy(() =>
    import("../Components/passengers/passengers")
  );
  const Passengersgroups = React.lazy(() =>
    import("../Components/passengers/passengersGroups/PassengersGroups")
  );
  const PassengersgroupDetails = React.lazy(() =>
    import(
      "../Components/passengers/passengersGroups/groupDetails/groupDetails"
    )
  );
  const PassengersWallet = React.lazy(() =>
    import("../Components/passengers/passengersWallet/passengerWallet")
  );
  const Drivers = React.lazy(() => import("../Components/drivers2/drivers"));
  const DriversGroups = React.lazy(() =>
    import("../Components/drivers2/driverGroups/driversGroups")
  );
  const DriversGroupsDetails = React.lazy(() =>
    import("../Components/drivers2/driverGroups/groupDetails/groupDetails")
  );
  const PassangerDetails = React.lazy(() =>
    import("../Components/passengerDetails/passengerdetails")
  );
  const Trips = React.lazy(() => import("../Components/trips/trips"));
  const TripsDetails = React.lazy(() =>
    import("../Components/tripdetails/tripdetails")
  );
  const Pricing = React.lazy(() => import("../Components/pricing/pricing"));
  // const AnonymousTrip = React.lazy(() => import('../Components/anonymousTrip/anonymousTriplayout'))
  const GeneralSettings = React.lazy(() =>
    import("../Components/settings/ganaral/settings")
  );
  const DriverShift = React.lazy(() =>
    import("../Components/drivers2/driverShiftManagement/shiftManagment")
  );
  const DriversWallet = React.lazy(() =>
    import("../Components/drivers2/driversWallet/driversWallet")
  );
  const DriverPromotion = React.lazy(() =>
    import("../Components/drivers2/driverPromotion/driverPromotion")
  );
  const VehiclesManagement = React.lazy(() =>
    import("../Components/vehiclesManagement/vehiclesManagement")
  );
  const CarClasses = React.lazy(() =>
    import("../Components/carClasses/carClasses")
  );
  const ScheduledTripetails = React.lazy(() =>
    import("../Components/scheduledTripdetails/tripdetails")
  );

  const ScheduledTrips = React.lazy(() =>
    import("../Components/scheduledTrips/scheduledTrips")
  );
  const ScheduleTrips = React.lazy(() =>
    import("../Components/scheduleTripsWithCalender/ScheduledTrips")
  );
  const PromoCode = React.lazy(() =>
    import("../Components/promoCode/promoCode")
  );
  const UsersGroups = React.lazy(() =>
    import("../Components/settings/usersGroups/usersGroups")
  );
  const UsersGroupsDetails = React.lazy(() =>
    import("../Components/settings/usersGroups/groupDetails/groupDetails")
  );
  const RulesManagement = React.lazy(() =>
    import("../Components/drivers2/rulesManagement/rulesManagement")
  );
  const ShiftsManagement = React.lazy(() =>
    import("../Components/drivers2/shiftsManagement/shiftsManagement")
  );
  const Garages = React.lazy(() => import("../Components/garages/garages"));
  const GarageDetails = React.lazy(() =>
    import("../Components/garages/GarageDetails/GarageDetails")
  );
  const NotificationsCenter = React.lazy(() =>
    import("../Components/notificationsCenter/NotificationsCenter")
  );

  const ManualDispatchPage = React.lazy(() =>
    import("../Components/manualDispatch/ManualDispatchPage")
  );
  const UsersManagement = React.lazy(() =>
    import("../Components/settings/userManagment/UserManagment")
  );
  const NotAuthPage = React.lazy(() =>
    import("../Components/notAuthPage/NotAuthPage")
  );
  const StaffGroupDetails = React.lazy(() =>
    import(
      "../Components/settings/userManagment/groupDetails/staffGroupDetails"
    )
  );
  const StaffLogsPage = React.lazy(() =>
    import("../Components/settings/userManagment/staff-logs/staff-logs-page")
  );
  const NotificationHistory = React.lazy(() =>
    import("../Components/notification-history/notification-history")
  );
  const MapDrawin = React.lazy(() =>
    import("../Components/notification-history/notification-history")
  );

  const DrawingPage = React.lazy(() =>
    import("../Components/drawingMap/DrawingPage")
  );

  return (
    <div
      style={{
        flexGrow: "1",
        height: "100%",
      }}
    >
      <Routes>
        <Route
          path={Paths.Home}
          element={
            <Authuser>
              <Suspense fallback={Spinner}>
                {" "}
                <Home />
              </Suspense>
            </Authuser>
          }
        />
        <Route
          path={Paths.DrawingMap}
          element={
            <Authuser>
              <Suspense fallback={Spinner}>
                {" "}
                <DrawingPage />
              </Suspense>
            </Authuser>
          }
        />
        <Route
          path={Paths.SiteMap}
          element={
            <Authuser>
              {" "}
              <Suspense fallback={Spinner}>
                <SiteMap />
              </Suspense>
            </Authuser>
          }
        />
        <Route
          path={Paths.DriverList}
          element={
            <Authuser>
              {" "}
              <Suspense fallback={Spinner}>
                <Drivers />
              </Suspense>
            </Authuser>
          }
        />
        <Route
          path={Paths.DriversGroups}
          element={
            <Authuser>
              {" "}
              <Suspense fallback={Spinner}>
                <DriversGroups />
              </Suspense>
            </Authuser>
          }
        />
        <Route
          path={Paths.DriversGroupsDetails}
          element={
            <Authuser>
              {" "}
              <Suspense fallback={Spinner}>
                <DriversGroupsDetails />
              </Suspense>
            </Authuser>
          }
        />
        <Route
          path={Paths.PassengersList}
          element={
            <Authuser>
              <Suspense fallback={Spinner}>
                {" "}
                <Passengers />
              </Suspense>
            </Authuser>
          }
        />
        <Route
          path={Paths.Passengersgroups}
          element={
            <Authuser>
              <Suspense fallback={Spinner}>
                {" "}
                <Passengersgroups />
              </Suspense>
            </Authuser>
          }
        />
        <Route
          path={Paths.PassengersgroupDetails}
          element={
            <Authuser>
              <Suspense fallback={Spinner}>
                {" "}
                <PassengersgroupDetails />
              </Suspense>
            </Authuser>
          }
        />
        <Route
          path={Paths.pWallet}
          element={
            <Authuser>
              <Suspense fallback={Spinner}>
                {" "}
                <PassengersWallet />
              </Suspense>
            </Authuser>
          }
        />
        <Route
          path={Paths.passengerTransactionDetails}
          element={
            <Authuser>
              <Suspense fallback={Spinner}>
                {" "}
                <PassengerTransactionDetails />
              </Suspense>
            </Authuser>
          }
        />
        <Route
          path={Paths.usersManagement}
          element={
            <Authuser>
              <Suspense fallback={Spinner}>
                {" "}
                <UsersManagement />
              </Suspense>
            </Authuser>
          }
        />
        <Route
          path={Paths.Station}
          element={
            <Authuser>
              <Suspense fallback={Spinner}>
                {" "}
                <Stations />
              </Suspense>
            </Authuser>
          }
        />
        <Route
          path={Paths.DriverDetail}
          element={
            <Authuser>
              <Suspense fallback={Spinner}>
                <DriverDetails />
              </Suspense>
            </Authuser>
          }
        />
        <Route
          path={Paths.PassengerDetail}
          element={
            <Authuser>
              <Suspense fallback={Spinner}>
                <PassangerDetails />
              </Suspense>
            </Authuser>
          }
        />
        <Route
          path={Paths.TripDetails}
          element={
            <Authuser>
              <Suspense fallback={Spinner}>
                <TripsDetails />
              </Suspense>
            </Authuser>
          }
        />
        <Route
          path={Paths.PricingList}
          element={
            <Authuser>
              <Suspense fallback={Spinner}>
                <Pricing />
              </Suspense>
            </Authuser>
          }
        />
        {/* <Route path={Paths.anonTrip} element={<Authuser><Suspense fallback={Spinner}><AnonymousTrip /></Suspense></Authuser>} /> */}
        <Route
          path={Paths.LoginPage}
          element={
            <NonAuthUser>
              <Suspense fallback={Spinner}>
                <Login />
              </Suspense>
            </NonAuthUser>
          }
        />
        {/* <Route path={Paths.general} element={<Authuser> <Suspense fallback={Spinner}><GeneralSettings /></Suspense></Authuser>} /> */}
        {/* <Route path={'/test'} element={<Authuser> <Suspense fallback={Spinner}><GeneralSettings2 /></Suspense></Authuser>} /> */}
        <Route
          path={Paths.driverShift}
          element={
            <Authuser>
              {" "}
              <Suspense fallback={Spinner}>
                <DriverShift />
              </Suspense>
            </Authuser>
          }
        />
        <Route
          path={Paths.driversWallet}
          element={
            <Authuser>
              {" "}
              <Suspense fallback={Spinner}>
                <DriversWallet />
              </Suspense>
            </Authuser>
          }
        />
        <Route
          path={Paths.driverPromotion}
          element={
            <Authuser>
              {" "}
              <Suspense fallback={Spinner}>
                <DriverPromotion />
              </Suspense>
            </Authuser>
          }
        />
        <Route
          path={Paths.vehiclesManagement}
          element={
            <Authuser>
              {" "}
              <Suspense fallback={Spinner}>
                <VehiclesManagement />
              </Suspense>
            </Authuser>
          }
        />
        <Route
          path={Paths.carClasses}
          element={
            <Authuser>
              {" "}
              <Suspense fallback={Spinner}>
                <CarClasses />
              </Suspense>
            </Authuser>
          }
        />
        <Route
          path={Paths.cars}
          element={
            <Authuser>
              {" "}
              <Suspense fallback={Spinner}>
                <Cars />
              </Suspense>
            </Authuser>
          }
        />
        <Route
          path={Paths.TripsList}
          element={
            <Authuser>
              <Suspense fallback={Spinner}>
                {" "}
                <Trips />
              </Suspense>
            </Authuser>
          }
        />
        <Route
          path={Paths.scheduledTripetails}
          element={
            <Authuser>
              <Suspense fallback={Spinner}>
                {" "}
                <ScheduledTripetails />
              </Suspense>
            </Authuser>
          }
        />
        <Route
          path={Paths.scheduledTrips}
          element={
            <Authuser>
              <Suspense fallback={Spinner}>
                {" "}
                <ScheduledTrips />
              </Suspense>
            </Authuser>
          }
        />
        <Route
          path={Paths.ScheduleTrips}
          element={
            <Authuser>
              <Suspense fallback={Spinner}>
                {" "}
                <ScheduleTrips />
              </Suspense>
            </Authuser>
          }
        />
        <Route
          path={Paths.promoCode}
          element={
            <Authuser>
              <Suspense fallback={Spinner}>
                {" "}
                <PromoCode />
              </Suspense>
            </Authuser>
          }
        />
        <Route
          path={Paths.driverTransactionDetails}
          element={
            <Authuser>
              <Suspense fallback={Spinner}>
                {" "}
                <DriverTransactionDetails />
              </Suspense>
            </Authuser>
          }
        />
        <Route
          path={Paths.chat}
          element={
            <Authuser>
              <Suspense fallback={Spinner}>
                {" "}
                <Chat />
              </Suspense>
            </Authuser>
          }
        />
        <Route
          path={Paths.general}
          element={
            <Authuser>
              <Suspense fallback={Spinner}>
                {" "}
                <GeneralSettings />
              </Suspense>
            </Authuser>
          }
        />
        <Route
          path={Paths.UsersGroups}
          element={
            <Authuser>
              <Suspense fallback={Spinner}>
                {" "}
                <UsersGroups />
              </Suspense>
            </Authuser>
          }
        />
        <Route
          path={Paths.UsersGroupsDetails}
          element={
            <Authuser>
              <Suspense fallback={Spinner}>
                {" "}
                <UsersGroupsDetails />
              </Suspense>
            </Authuser>
          }
        />
        <Route
          path={Paths.RulesManagement}
          element={
            <Authuser>
              <Suspense fallback={Spinner}>
                {" "}
                <RulesManagement />
              </Suspense>
            </Authuser>
          }
        />
        <Route
          path={Paths.ShiftsManagement}
          element={
            <Authuser>
              <Suspense fallback={Spinner}>
                {" "}
                <ShiftsManagement />
              </Suspense>
            </Authuser>
          }
        />
        <Route
          path={Paths.Garages}
          element={
            <Authuser>
              <Suspense fallback={Spinner}>
                {" "}
                <Garages />
              </Suspense>
            </Authuser>
          }
        />
        <Route
          path={Paths.GarageDetails}
          element={
            <Authuser>
              <Suspense fallback={Spinner}>
                {" "}
                <GarageDetails />
              </Suspense>
            </Authuser>
          }
        />

        <Route
          path={Paths.anonymousTriplayout}
          element={
            <Authuser>
              <Suspense fallback={Spinner}>
                {" "}
                <ManualDispatchPage />
              </Suspense>
            </Authuser>
          }
        />

        <Route
          path={Paths.NotificationHistory}
          element={
            <Authuser>
              <Suspense fallback={Spinner}>
                {" "}
                <NotificationHistory />
              </Suspense>
            </Authuser>
          }
        />
        <Route
          path={Paths.notificationsCenter}
          element={
            <Authuser>
              <Suspense fallback={Spinner}>
                {" "}
                <NotificationsCenter />
              </Suspense>
            </Authuser>
          }
        />

        <Route
          path={Paths.UsersManagement}
          element={
            <Authuser>
              <Suspense fallback={Spinner}>
                {" "}
                <UsersManagement />
              </Suspense>
            </Authuser>
          }
        />
        <Route
          path={Paths.NotAuth}
          element={
            <Authuser>
              <Suspense fallback={Spinner}>
                {" "}
                <NotAuthPage />
              </Suspense>
            </Authuser>
          }
        />

        <Route
          path={Paths.StaffGroupDetails}
          element={
            <Authuser>
              <Suspense fallback={Spinner}>
                {" "}
                <StaffGroupDetails />
              </Suspense>
            </Authuser>
          }
        />
        <Route
          path={Paths.StaffLogsPage}
          element={
            <Authuser>
              <Suspense fallback={Spinner}>
                {" "}
                <StaffLogsPage />
              </Suspense>
            </Authuser>
          }
        />

        <Route path="*" element={<Notfound />} />
      </Routes>
    </div>
  );
}
