import { useTranslation } from 'react-i18next';
import styles from './loader.module.css'
const Loading = () => {
    const { i18n } = useTranslation();

    return (

        <div>

            <div className={styles.custom_class}>
                <div className={styles.spin_container}>
                    <div className={styles.spin + ' ' + styles.loader} ></div>
                    <div className={styles.spin + ' ' + styles.loader2}></div>
                    <div className={styles.spin + ' ' + styles.loader3}></div>
                    <div className={styles.spin + ' ' + styles.loader4} ></div>
                    <div className={styles.geeks + ' ' + styles.text}  >
                    {i18n.language==='en'&&
                        <>
                        <span>J</span>
                        {/* <span>E</span> */}
                        <span>T</span>
                        <span>N</span>
                        <span>Y</span>
                        </>}

                        

                    </div>
                    <div className={styles.geeks + ' ' + styles.text} style={{display:'flex',flexDirection:i18n.language==='en'?'row':'row-reverse'}} >
                    {/* {i18n.language==='en'&&
                        <> */}
                        <span>L</span>
                        <span>o</span>
                        <span>a</span>
                        <span>d</span>
                        <span>i</span>
                        <span>n</span>
                        <span>g</span>
                        <span>.</span>
                        <span>.</span>
                        <span>.</span> 
                        {/* </>} */}

                        {/* {i18n.language==='ar'&&
                        <>
                        <span>.</span>
                        <span>.</span>
                        <span>.</span>
                        <span>g</span>
                        <span>n</span>
                        <span>i</span>
                        <span>d</span>
                        <span>a</span>
                        <span>o</span>
                        <span>L</span>
 </>} */}
                    </div>
                  
                </div>
            </div>
        </div>

    )
}

export { Loading };
