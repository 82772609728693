import { createContext, useState } from "react";
import { Successmodal } from "../../../shared/successmodal/successmodal";
export const SucMsgContext = createContext()
const SucMsgProvider = ({ children }) => {
    const [successMsg, setSuccessMsg] = useState('');
    // 
    const updateSucMsg = (errMsg) => {
        setSuccessMsg(errMsg)
    }
    // 
    return (
        <SucMsgContext.Provider value={{ updateSucMsg,successMsg }}>
            {children}
            {successMsg && <Successmodal successMsg={successMsg} setSuccessMsg={setSuccessMsg} />}
        </SucMsgContext.Provider>
    );
}

export { SucMsgProvider };
