import styles from './notFound.module.css';

const Notfound = () => {
    return (
        <div style={{
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%,-50%)'
        }}>
            <h1 style={{ color: '#CC9AA6' }}>Path Not Resolved</h1>
            <section className={styles.error_container}>
                <span className={styles.four}><span className="screen-reader-text"></span></span>
                <span className={styles.zero}><span className="screen-reader-text "></span></span>
                <span className={styles.four}><span className="screen-reader-text"></span></span>
            </section>

        </div>
    );
}

export default Notfound;
