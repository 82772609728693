import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import ar from './Languages/ar.json';
import en from './Languages/en.json';


const resources = {
  en: {
    translation: en
  },
  ar: {
    translation: ar
  }
};

i18n
  .use(initReactI18next) 
  .init({
    resources,
    lng:localStorage.getItem('greenSelectedLanguage')?localStorage.getItem('greenSelectedLanguage'):"en", 

    interpolation: {
      escapeValue: false
    }
  });

  export default i18n;