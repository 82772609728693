import React, { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { AuthContext } from "../contexts/authContext/authContext";
const Authuser = ({ children }) => {
  const { currentUser } = useContext(AuthContext);

  const path = useLocation().pathname;
  const isAuthuser =
    (currentUser?.permissions?.includes("view_zones") &&
      path === "/drawing-map") ||
    (currentUser?.permissions?.includes("view_dashboard") && path === "/") ||
    (currentUser?.permissions?.includes("view_garage") &&
      path === "/garages") ||
    (currentUser?.permissions?.includes("view_garage") &&
      path === "/garagedetails") ||
    (currentUser?.permissions?.includes("view_notification_history") &&
      path === "/notification-history") ||
    (currentUser?.permissions?.includes("view_live_view") && path === "/map") ||
    (currentUser?.permissions?.includes("view_drivers_screen") &&
      (path === "/drivers" || path === "/driverdetails")) ||
    (currentUser?.permissions?.includes("view_driver_wallet") &&
      (path === "/driverwallet" || path === "/driver-transaction-details")) ||
    (currentUser?.permissions?.includes("view_driver_groups") &&
      (path === "/driversgroups" || path === "/driversgroupdetails")) ||
    (currentUser?.permissions?.includes("view_passengers_screen") &&
      (path === "/passengers" || path === "/passengerdetails")) ||
    (currentUser?.permissions?.includes("view_passengers_wallet") &&
      (path === "/pwallet" || path === "/Passenger-transaction-details")) ||
    (currentUser?.permissions?.includes("view_passengers_groups") &&
      (path === "/passengersgroups" || path === "/passengersgroupdetails")) ||
    (currentUser?.permissions?.includes("view_trips") &&
      (path === "/trip" || path === "/tripdetails")) ||
    (currentUser?.permissions?.includes("view_scheduled_trips_calender") &&
      path === "/scheduletrips") ||
    (currentUser?.permissions?.includes("view_scheduled_trips_general") &&
      (path === "/scheduledtrips" || path === "/scheduledTripetails")) ||
    (currentUser?.permissions?.includes("view_manual_dispatch") &&
      path === "/manual-dispatch") ||
    (currentUser?.permissions?.includes("view_vehicles_management") &&
      path === "/cars") ||
    (currentUser?.permissions?.includes("view_notification_center") &&
      path === "/notifications-center") ||
    (currentUser?.permissions?.includes("view_promo_code_management") &&
      path === "/promocode") ||
    (currentUser?.permissions?.includes("view_chat_system") &&
      path === "/chat") ||
    (currentUser?.permissions?.includes("view_general_settings") &&
      (path === "/general" || path === "/carclasses")) ||
    (currentUser?.permissions?.includes("view_users_group") &&
      (path === "/usersgroups" || path === "/usersgroupdetails")) ||
    (currentUser?.permissions?.includes("view_users_settings") &&
      (path === "/users-management" ||
        path === "/staff-group-details" ||
        path === "/staff-logs-page")) ||
    path === "/not-auth";
  if (!currentUser?.permissions?.includes("view_dashboard") && path === "/") {
    return <Navigate to="/map" />;
  }
  if (currentUser) {
    if (isAuthuser) {
      return children;
    }

    return <Navigate to="/not-auth" />;
  } else {
    return <Navigate to="/login" />;
  }
};

export { Authuser };
