import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../contexts/authContext/authContext';

const NonAuthUser = ({children}) => {
    const { currentUser } = useContext(AuthContext)
    if (!currentUser) {
        return children
    } else {
        return <Navigate to="/" />
    }
}

export  {NonAuthUser};
